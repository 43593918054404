import React from 'react';
import { arrayOf, object, shape } from 'prop-types';
import { connect } from 'react-redux';
import Accordion from 'yoda-core-components/lib/components/Accordion/accordion';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './SeoFacetCopy.css';
import sanitizeHtml from '../../utils/sanitizeHtml';
import { getSeoTag } from '../../selectors/SeoTags';

const cx = classNames.bind(styles);

const SeoCopyText = ({ seoTags: { seoFacets = [] } = {} }) => {
    let AccrContent = [];
    if (seoFacets && seoFacets.length > 0) {
        /* istanbul ignore next */
        AccrContent = seoFacets.map((key) => {
            return {
                header: key.facetName,
                panel: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(key.facetCopyBlock),
                        }}
                    />
                ),
            };
        });
    }

    return (
        <div className={`${cx('wrapper')} ${dt(['my-4', 'mx-2'])}`}>
            <Accordion>{AccrContent}</Accordion>
        </div>
    );
};

SeoCopyText.defaultProps = { seoTags: { seoFacets: [] } };

SeoCopyText.propTypes = {
    seoTags: shape({
        seoFacets: arrayOf(object),
    }),
};

const mapStateToProps = (store) => ({ seoTags: getSeoTag(store) });

export default connect(mapStateToProps)(SeoCopyText);
