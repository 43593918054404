import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { bool, func, object, objectOf, string } from 'prop-types';
import { connect } from 'react-redux';
import ConnectedSeoCopyText from '../SeoCopyText/SeoCopyText';
import SeoFacetCopy from '../SeoFacetCopy/SeoFacetCopy';
import { getSeoContentWithIris } from '../../actions/GalleryIrisAction';
import { getSeoTag } from '../../selectors/SeoTags';

export class Seo extends PureComponent {
    static propTypes = {
        actions: objectOf(func).isRequired,
        seoTags: object.isRequired,
        isNative: bool,
        componentType: string,
    };

    static defaultProps = {
        isNative: false,
        componentType: '',
    };

    componentDidMount() {
        const { seoTags, actions } = this.props;
        actions.getSeoContentWithIris({ seoTags });
    }

    render() {
        const { componentType, isNative } = this.props;
        const shouldRender = !isNative || (isNative && componentType !== 'seoblock');

        return shouldRender ? (
            <>
                <ConnectedSeoCopyText />
                <SeoFacetCopy />
            </>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    const { context: { isNative = false } = {} } = state;

    return {
        isNative,
        seoTags: getSeoTag(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getSeoContentWithIris,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Seo);
