import React from 'react';
import { bool, shape, string } from 'prop-types';
import DHTML from 'yoda-site-components/lib/components/DHTML/DHTML';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './SeoCopyText.css';
import { getSeoTag } from '../../selectors/SeoTags';
import { getURLParameterBykey } from '../../utils';

const cx = classNames.bind(styles);

export const SeoCopyText = ({ seoTags: { copyText = '' }, featureFlags: { enableShowMore } }) => {
    const isFirstPage = !(parseInt(getURLParameterBykey('page'), 10) > 1);
    const hasCopyText = copyText.trim().length > 0;
    const wrapperClasses = `${dt(['my-0', 'mx-2', 'pt-2', 'text-black', 'text-sm'])} ${cx(
        'wrapper'
    )}`;

    return hasCopyText && isFirstPage ? (
        <div className={wrapperClasses}>
            <DHTML html={copyText} automationId="seo-content" showMore={enableShowMore} />
        </div>
    ) : null;
};

SeoCopyText.defaultProps = {
    featureFlags: {
        enableShowMore: false,
    },
    seoTags: {
        copyText: '',
    },
};

SeoCopyText.propTypes = {
    featureFlags: shape({
        enableShowMore: bool,
    }),
    seoTags: shape({
        copyText: string,
    }),
};

const mapStateToProps = (store) => ({
    featureFlags: store.context.featureFlags,
    seoTags: getSeoTag(store),
});

export default connect(mapStateToProps)(SeoCopyText);
